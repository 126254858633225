import React, {useState, useEffect} from 'react';
import {Modal, Button, Form} from "react-bootstrap";

function QualityAssuranceComplete({hearingId}) {

    const [finalFile, setFinalFile] = useState(false);
    const [qaUser, setQAUser] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    const[formFile, setFormFile] = useState('');


    let checkForCompletion = (e) => {
        let url = 'https://' + location.hostname + '/hearing/' + hearingId + '/transcription/validate';
        $.ajax({
            url: url,
            type: 'POST',
            success: function(response) {
                if (response.error) {
                    $('section.content .sonata-ba-form').before('<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true" aria-label="Close"> x </button> ' + response.error_message + '</div>');
                } else if (response) {
                    setFinalFile(response.finalTranscription);
                    setQAUser(response.qaUser);
                    $('div.alert').remove();
                    handleModalShow();
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    let handleFormChange = (e) => {
        if (e.target) {
            if (e.target.name === 'file') {
                setFormFile(e.target.files[0]);
            }
        }
    };

    let handleFileSubmit = (e) => {
        handleFileSubmitToDatabase(formFile);
    };

    let handleFileSubmitToDatabase = (formFile) => {
        if (!qaUser && finalFile && !formFile) {
            let url = 'https://' + location.hostname + '/hearing/' + hearingId + '/transcription/complete';
            $.ajax({
                url: url,
                type: 'POST',
                data: {
                    id: hearingId,
                },
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        window.location.pathname = response.url;
                        handleModalClose();
                    }

                }.bind(this),
                error: function(xhr) {
                    console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
                }
            });

        } else {
            let files = new FormData();
            files.append('fileName', formFile);

            let url = 'https://' + location.hostname + '/hearing/' + hearingId + '/transcription/add-file';
            $.ajax({
                url: url,
                type: 'POST',
                data: files,
                processData: false,
                contentType: false,
                success: function(response) {

                    if (response.success) {
                        window.location.pathname = response.url;
                        handleModalClose();
                    }

                    if (response.error) {
                        // $('.modal-body .form-group').addClass('has-error');
                        $('.modal-body .form-group').after('<div class="error-message"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> ' + response.message + '</div>');

                    }
                }.bind(this),
                error: function(xhr) {
                    console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
                }
            });
        }
    };

return (
        <div>
            <Modal
                show={modalShow}
                onHide={handleModalClose}
                animation={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Final Transcription File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { !qaUser && finalFile ?
                    <p>
                        A Transcription File has been uploaded. If you want to upload a newer version, you can select a
                        file and save.
                    </p>
                        :
                        <></>
                    }
                    <Form.Group controlId="formName">
                        <Form.Label>Transcription File</Form.Label>
                        <Form.File
                            id="custom-file"
                            name="file"
                            custom
                            onChange={handleFormChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFileSubmit}>
                        Submit Final Transcription
                    </Button>
                </Modal.Footer>
            </Modal>

            <button className="btn btn-sm btn-primary" onClick={checkForCompletion}>
                <i className="fa fa-check" aria-hidden="true"></i> Quality Assurance Complete
            </button>
        </div>
)

}

export default QualityAssuranceComplete;