import React from 'react';
import ReactDOM from 'react-dom';
import Document from "./components/Document";
import PanelPresent from "./components/PanelPresent";
import OthersPresent from "./components/OthersPresent";
import QualityAssuranceComplete from "./components/QualityAssuranceComplete";
import QualityAssuranceRevert from "./components/QualityAssuranceRevert";

const transcriptionDocument = document.getElementById('transcription-document-root');

if (transcriptionDocument) {
    try {
        ReactDOM.render(<Document {...(transcriptionDocument.dataset)}/>, transcriptionDocument);
    } catch (error) {
        console.error(error);
    }
}

const panelPresentSpeakers = document.getElementById('panel-present-root');

if (panelPresentSpeakers) {
    try {
        ReactDOM.render(<PanelPresent {...(panelPresentSpeakers.dataset)}/>, panelPresentSpeakers);
    } catch (error) {
        console.error(error);
    }
}

const otherPresentSpeakers = document.getElementById('others-present-root');

if (otherPresentSpeakers) {
    try {
        ReactDOM.render(<OthersPresent {...(otherPresentSpeakers.dataset)}/>, otherPresentSpeakers);
    } catch (error) {
        console.error(error);
    }
}

const qualityAssuranceComplete = document.getElementById('quality-assurance-complete');

if (qualityAssuranceComplete) {
    try {
        ReactDOM.render(<QualityAssuranceComplete {...(qualityAssuranceComplete.dataset)}/>, qualityAssuranceComplete);
    } catch (error) {
        console.error(error);
    }
}

const qualityAssuranceRevert = document.getElementById('quality-assurance-revert');

if (qualityAssuranceRevert) {
    try {
        ReactDOM.render(<QualityAssuranceRevert {...(qualityAssuranceRevert.dataset)}/>, qualityAssuranceRevert);
    } catch (error) {
        console.error(error);
    }
}