import React, {useState, useEffect} from 'react';
import {Modal, Button, Form} from "react-bootstrap";

function QualityAssuranceRevert({hearingId}) {

    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    const[formFile, setFormFile] = useState('');

    let revertBack = () => {

        let url = 'https://' + location.hostname + '/hearing/' + hearingId + '/transcription/revert';

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: hearingId,
            },
            dataType: 'json',
            success: function(response) {
                if (response) {
                    window.location.pathname = response.url;
                }
            }.bind(this),
            error: function(xhr) {
                console.log(`An error occured: ${xhr.status} ${xhr.statusText}`);
            }
        });
    };

    return (
        <div>
            <Modal
                show={modalShow}
                onHide={handleModalClose}
                animation={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Hearing Back to QA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to send this back to Quality Assurance?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={revertBack}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <button className="btn btn-sm btn-primary" onClick={handleModalShow}>
                <i className="fa fa-check" aria-hidden="true"></i> Back to Quality Assurance
            </button>
        </div>
    )

}

export default QualityAssuranceRevert;